import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MoreBlogPosts from "../components/more-blog-posts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const ThankYouContact = ({ data }) => (
  <Layout>
    <SEO
      title="Thank you for contacting us"
      description="Thank you for contacting us. We'll get back to you as soon as possible."
      noindex
    />

    <section className="container pt-11 my-5">
      <div className="row justify-content-center">
        <div className="col-12 text-center mb-8">
          <h1 className="display-1 font-weight-bold">Thank You!</h1>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="6x"
            style={{ color: "#42ba96" }}
            className="mx-auto my-3"
          />
        </div>
        <p className="font-size-lg text-muted text-center">
          For contacting us - we'll get back to you as soon as possible.
          <br />
          Meanwhile, feel free to check out our blog:
        </p>
      </div>
    </section>

    <section className="container my-5">
      <div className="row">
        <MoreBlogPosts />
      </div>
    </section>
  </Layout>
)

export default ThankYouContact
